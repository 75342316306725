export default {
   input: {
      outer: 'mb-4',
      label: ' mb-2 block text-sm text-black font-medium ',
      inner: 'bg-white w-full border border-gray-40  rounded-xl mb-1 overflow-hidden focus-within:ring-2 flex items-center justify-between',
      input: ' bg-white w-full h-10 px-3 text-gray-700 opacity-100 focus:outline-none text-sm  ',
      help: 'mt-1 text-primary-500 text-xs',
      message: 'text-error-500 text-xs',
      messages: 'text-error-500 text-xs',
      'incomplete-message': 'text-error-500 mt-2 ml-1 text-md',
   },
   inputForm: {
      label: 'text-gray-700 text-xs block pb-2 ',
      inner: 'bg-white w-full border border-gray-40 rounded mb-1 px-3 overflow-hidden focus-within:ring-2 flex items-center',
      icon: 'flex items-center mr-2 text-base h-5 w-5 shrink-0 [&>svg]:w-full text-gray-500',
      input: 'bg-white w-full h-10 text-gray-700 opacity-100 focus:outline-none',
      message: 'text-error-500 text-xs',
      messages: 'text-error-500 text-xs',
      'incomplete-message': 'text-error-500 text-xs',
   },
   otp: {
      label: 'text-gray-700 text-xs block pb-2 ',
      inner: 'bg-white w-full border border-secondary-500 rounded-2xl mb-1 px-3 overflow-hidden focus-within:ring-2 flex items-center',
      icon: 'flex items-center mr-2 text-base h-5 w-5 shrink-0 [&>svg]:w-full text-gray-500',
      input: 'bg-white w-full placeholder:text-base placeholder:text-start h-16 text-center text-2xl text-gray-700 opacity-100 focus:outline-none',
      message: 'text-error-500 text-xs',
      messages: 'text-error-500 text-xs',
      'incomplete-message': 'text-error-500 text-xs',
   },
   textarea: {
      label: 'text-gray-700 text-xs block pb-2 ',
      placeholder: 'text-gray-700 text-xs  ',
      inner: 'bg-white w-full border border-gray-40 rounded overflow-hidden focus-within:ring-2 flex items-center',
      input: 'w-full min-h-36 max-h-96  text-gray-700 opacity-100 focus:outline-none bg-white text-sm  placeholder:text-xs p-3',
      message: 'text-error-500 text-xs',
      messages: 'text-error-500 text-xs mt-2 ms-1',
      'incomplete-message': 'text-error-500 text-xs',
      help: 'mt-2 text-secondary-800 text-md',
   },
};
